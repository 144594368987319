// dependencies
import React, { useMemo } from "react";
import { View, TouchableWithoutFeedback, StyleSheet } from "react-native";

// libraries
import EnhancedDate from "@gdf/resources/src/libraries/EnhancedDate";
import { emToPx } from "@gdf/resources/src/libraries";

// components
import Text from "@gdf/resources/src/components/Text";

// contexts
import { useDatePicker } from "./datePicker";

type PropsType = {
  date: EnhancedDate;
  type: "normal" | "leading" | "trailing";
};

const styles = StyleSheet.create({
  datePickerDay: {
    flexGrow: 1,
    flexBasis: "0",
    flexShrink: 0,
    height: emToPx(2.0625),
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#cfdadd",
    marginRight: -1,
    marginBottom: -1,
    paddingRight: 1,
    paddingBottom: 1,
    cursor: "pointer",
  },
});

const getColors = ({ date, hover, start, end, type, min }) => {
  if (date.isLessThan(min)) {
    return {
      backgroundColor: "#eeeeef",
      color: "#d4d4d4",
      interactive: false,
    };
  }

  if ("leading" === type || "trailing" === type) {
    return {
      backgroundColor: "#eeeeef",
      color: "#d4d4d4",
      interactive: false,
    };
  } else if ("normal" === type) {
    if (date.isEqualTo(hover)) {
      return {
        backgroundColor: "#156543",
        color: "#ffffff",
        interactive: true,
      };
    } else if (date.isEqual(start)) {
      return {
        backgroundColor: "#156543",
        color: "#ffffff",
        interactive: true,
      };
    } else if (date.isEqual(end)) {
      return {
        backgroundColor: "#156543",
        color: "#ffffff",
        interactive: true,
      };
    } else if (date.isGreaterThan(start) && date.isLessThan(end)) {
      return {
        backgroundColor: "#1F9462",
        color: "#ffffff",
        interactive: true,
      };
    } else if (
      date.isGreaterThan(start) &&
      date.isLessThan(hover) &&
      null === end
    ) {
      return {
        backgroundColor: "#1F9462",
        color: "#ffffff",
        interactive: true,
      };
    }
  }

  return {
    backgroundColor: "#ffffff",
    color: "#000000",
    interactive: true,
  };
};

const DatePickerDay: React.FunctionComponent<PropsType> = (props) => {
  const { date, type } = props;

  const { start, end, hover, min, onChangeHover, onChangeSelect } =
    useDatePicker();

  const { interactive, backgroundColor, color } = getColors({
    date,
    hover,
    type,
    start,
    end,
    min,
  });

  return useMemo(() => {
    return (
      <TouchableWithoutFeedback onPress={() => onChangeSelect(date)}>
        <View
          style={[
            styles.datePickerDay,
            {
              backgroundColor,
            },
          ]}
          {...(interactive && {
            onMouseEnter: () => onChangeHover(date),
            onMouseLeave: () => onChangeHover(null),
          })}
        >
          <Text style={{ color }}>{date.format("j")}</Text>
        </View>
      </TouchableWithoutFeedback>
    );
  }, [
    date,
    color,
    backgroundColor,
    interactive,
    onChangeSelect,
    onChangeHover,
  ]);
};

export default DatePickerDay;
