// dependencies
import React from "react";
import { View } from "react-native";
import { unstable_createElement } from "react-native-web";

type PropsType = {} & React.ComponentProps<"form">;

const Form: React.FunctionComponent<PropsType> = (props) => {
  const { children, ...otherProps } = props;

  return unstable_createElement(
    "form",
    otherProps,
    <View>
      <input
        type="submit"
        style={{
          zIndex: -1,
          visibility: "hidden",
          width: 0,
          height: 0,
          margin: 0,
          padding: 0,
          border: 0,
        }}
      />
      {children}
    </View>
  );
};

Form.defaultProps = {
  method: "get",
  encType: "multipart/form-data",
};

export default Form;
